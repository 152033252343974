import React, { useState, useRef, useContext, useEffect } from 'react';
import { graphql } from 'gatsby';
import { GatsbyImage } from 'gatsby-plugin-image';
import PortableText from 'react-portable-text';
import loadable from '@loadable/component';

import { Container, Grid, Typography, useMediaQuery } from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';

import { Hero } from '../components/General/Hero';
import { WaveUpSVG } from '../components/WaveSVGs/WaveUpSVG';
import { SEO } from '../components/SEO';
import { ListItem } from '../components/CustomStyles/Typography';

import FormModalContext from '../context/FormModalContext';

const Form = loadable(() => import('../components/ContactForm/Form'));

const useStyles = makeStyles((theme) => ({
	text: {
		color: '#4B5B69',
		lineHeight: 1.8,
		fontFamily: 'Roboto',
		'& a': {
			color: theme.workwaveBlue,
		},
	},
  listStyle: {
    fontSize: '1.125rem',
		color: '#4B5B69',
		lineHeight: 1.8,
		fontFamily: 'Roboto',
  },
}));

const EOLPage = ({ data, location }) => {
  const classes = useStyles();
  const eol = data.eol.edges[0].node;
  const { metaDescription, metaTitle, hero, noindex, logos, _rawPageContent } = eol;

  const { formModalOpen } = useContext(FormModalContext);

  const lg = useMediaQuery('(max-width: 1280px)');

  return (
    <>
      <SEO title={metaTitle} description={metaDescription} noindex={noindex} />
      <Hero hero={hero} />
      <Container style={{ marginBottom: lg ? '0rem' : '16rem' }}>
        <Grid
          container
          spacing={4}
          style={{ paddingBottom: '2rem' }}
          direction='row'
          justifyContent='center'
          alignItems='center'>
          {logos.map((logo, index) => {
            return (
              <Grid
                item
                key={index}
                xs={12}
                sm={3}>
                <GatsbyImage 
                  image={logo.logo?.asset?.gatsbyImageData} 
                  imgStyle={{ objectFit: 'contain' }} />
              </Grid>
            );
          })}
        </Grid>
				<Grid Container>
          <Grid
            item
            xs={12}>
            <PortableText
              content={_rawPageContent}
              serializers={{
                normal: ({ children }) => (
                  <Grid item style={{ margin: '1rem 0' }}>
                    <Typography variant='body1' className={classes.text}>
                      {children}
                    </Typography>
                  </Grid>
                ),
                strong: ({ children }) => (
                  <span style={{ fontWeight: 600 }}>{children}</span>
                ),
                emailPhoneLink: (props) => {
                  return <a href={props.emailPhoneHref} style={{ color: '#4B5B69' }}>{props.children[0]}</a>;
                },
                link: (props) => {
                  return (
                    <a
                      href={props.href}
                      target='_blank'
                      rel='noreferrer'
                      style={{ color: '#4B5B69' }}>
                      {props.children[0]}
                    </a>
                  );
                },
                li: ({ children }) => (
                  <Grid item>
                    <ListItem>
                      <li className={classes.listStyle}>
                        {children}
                      </li>
                    </ListItem>
                  </Grid>
                ),
              }}
            />
          </Grid>
				</Grid>

        {formModalOpen ? (
          <Form
            noForm
            location={location}
            pardotUrl='https://go.workwave.com/l/1042281/2023-11-16/65swnn'
          />
        ) : null}
      </Container>
      <div style={{ marginTop: '12rem' }}>
        <WaveUpSVG height='213' width='100%' fill='#f4f8ff' />
      </div>
    </>
  );
};

export const query = graphql`
  query EOLPageQuery {
    eol:     allSanityEol {
      edges {
        node {
          metaTitle
          metaDescription
          hero {
            _rawContent
            backgroundImage {
              asset {
                gatsbyImageData(placeholder: BLURRED)
              }
            }
          }
          noindex
          logos {
            logo {
              asset {
                gatsbyImageData(placeholder: BLURRED, height: 64, width: 250)
              }
            }
          }
          _rawPageContent
        }
      }
    }
  }
`;

export default EOLPage;
